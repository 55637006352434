var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"main-view"},[_c('div',{staticClass:"old-lk-wrapper"},[(_vm.fromOldLK)?_c('custom-alert',{attrs:{"alertType":"warning","messages":[_vm.$t('login.oldLKContent')],"deletable":false,"onlyContent":true,"wordBreak":true,"fillWidth":""}}):_vm._e()],1),_c('div',{staticClass:"main-view_tabs",class:{
      'main-view_tabs--activeRegister': _vm.$route.name === 'SignUp',
      'mt-2': _vm.$vuetify.breakpoint.mdAndUp,
      'mt-2': _vm.$vuetify.breakpoint.mdAndUp,
      'mt-0': _vm.$vuetify.breakpoint.smAndDown
    }},_vm._l((_vm.tabs),function(tab,key){return _c('span',{key:key,staticClass:"main-view_tab",class:{
        'main-view_tab--active': tab.to.name === _vm.$route.name
      },on:{"click":function($event){return _vm.handleTabClick(tab.to)}}},[_vm._v(" "+_vm._s(_vm.$t(tab.name))+" ")])}),0),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }