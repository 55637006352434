
import Vue from 'vue'
import { mapGetters } from 'vuex'

interface TabsInterface {
  name: string,
  to: {
    name: string
  }
}

export default Vue.extend({
  name: 'MainView',
  data: () => ({
    fromOldLK: false,
    tabs: [
      {
        name: 'main.tabs.signIn',
        to: {
          name: 'SignIn'
        }
      },
      {
        name: 'main.tabs.signUp',
        to: {
          name: 'SignUp'
        }
      }
    ] as Array<TabsInterface>
  }),
  computed: {
    ...mapGetters('user', ['getIsAuthenticated'])
  },
  watch: {
    getIsAuthenticated (isAuthenticated): void {
      const vm = this as any
      if (isAuthenticated) {
        vm.$router.replace({ name: 'whitelistID' })
      }
    }
  },
  methods: {
    handleTabClick (to: TabsInterface) {
      const vm = this as any
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to)
      }
    }
  },
  created (): void {
    const vm = this as any
    this.fromOldLK = Boolean(this.$route.query.from_oldlk !== undefined)
    this.fromOldLK = Boolean(this.$route.query.from_oldlk !== undefined)
    if (vm.getIsAuthenticated) {
      vm.$router.replace({ name: 'whitelistID' })
    }
  }
})
